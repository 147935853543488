/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "i Naeem",
  title: "Hi all, I'm Naeem",
  subTitle: emoji(
    "A passionate Full Stack Software Developer 🚀 having an experience of building Web and Mobile applications with JavaScript / Reactjs / Nodejs / React Native and some other cool libraries and frameworks."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1K7vbogh1O8bKOxwQMrnVIVdUkTcbIdoz/view?usp=share_link", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/ahmednaeem13",
  linkedin: "https://www.linkedin.com/in/abdelnaeem/",
  gmail: "inaeem.online@gmail.com",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
    ),
    emoji("⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"),
    emoji(
      "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean"
    ),
    emoji(
      "⚡ Experienced in Supply Chain with a demonstrated history of working in the pharmaceuticals and electronics industry. Strong operations professional skilled in Communication, ERP systems (SAP, Oracle) Power BI & Excel Dashboards, Reports"
    )
    
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

softwareSkills: [
  {
    skillName: "Power BI",
    fontAwesomeClassname: "fas fa-chart-line" // أيقونة بديلة تمثيلية لـ Power BI
  },
  {
    skillName: "Excel",
    fontAwesomeClassname: "fas fa-file-excel" // أيقونة Excel من Font Awesome
  },
  {
    skillName: "Tableau",
    fontAwesomeClassname: "fas fa-chart-pie" // أيقونة تمثيلية لـ Tableau
  },
  {
    skillName: "Power Query",
    fontAwesomeClassname: "fas fa-database" // أيقونة تمثيلية لـ Power Query
  },
  {
    skillName: "Power Pivot",
    fontAwesomeClassname: "fas fa-table" // أيقونة تمثيلية لـ Power Pivot
  },
  {
    skillName: "GPT",
    fontAwesomeClassname: "fas fa-robot" // أيقونة تمثيلية لـ GPT
  },
  {
    skillName: "SAP",
    fontAwesomeClassname: "fas fa-cogs" // أيقونة تمثيلية لـ SAP
  },
  {
    skillName: "Oracle",
    fontAwesomeClassname: "fas fa-database" // أيقونة تمثيلية لـ Oracle
  },
  {
    skillName: "Microsoft Access",
    fontAwesomeClassname: "fas fa-database" // أيقونة تمثيلية لـ Microsoft Access
  },
  {
    skillName: "SQL Database",
    fontAwesomeClassname: "fas fa-database"
  },
  {
    skillName: "JavaScript",
    fontAwesomeClassname: "fab fa-js"
  },
  {
    skillName: "Python",
    fontAwesomeClassname: "fab fa-python"
  },
  {
    skillName: "AWS",
    fontAwesomeClassname: "fab fa-aws"
  }
],
display: true // Set false to hide this section, defaults to true

};

// Education Section

const educationInfo = {
  display: false, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Arizona State University",
      logo: require("./assets/images/harvardLogo.png"),
      subHeader: "Prompt Engineering with ChatGPT",
      duration: "September 2022 - May 2024",
      desc: "Participated in the research of XXX and published 3 papers.",
      descBullets: [
        "AI Foundations: Prompt Engineering with ChatGPT"
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Data Analysis - Dashboards", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Frontend/Design - Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "ERP Systems - Database",
      progressPercentage: "80%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Senior Supply Planner",
      company: "Nahdet Misr Group",
      companylogo: require("./assets/images/NMLogo.png"),
      date: "Dec 2023 – Present",
      desc: "Building Excel and Power BI Dashboards for all supply chain Sector to monitor the KPIs with time gone.",
      descBullets: [
        "Participate in building the SAP MRP implementation.",
        "Support reporting, inventory parameters, reduction and reserve percentage measurements, Product Life Cycle management."
      ]
    },
    {
      role: "Senior Planner & Data Analyst",
      company: "AL Andalous",
      companylogo: require("./assets/images/AndLogo.png"),
      date: "Jul 2021 – Dec 2023",
      desc: "Review inventory reports to determine reorder points and defines replenishment requirements for all materials requirements.",
      descBullets: [
        "Analyze, recommend and update safety stock levels, lead-times and order quantities for materials and finished goods.",
        "Design and publish supply chain performance metrics, including scorecards & dashboards, as needed (daily/weekly/monthly/quarterly)."
      ]
    },
    {
      role: "Procurement Planner",
      company: "LG Electronics",
      companylogo: require("./assets/images/LGLogo.png"),
      date: "Mar 2017 – Mar 2019",
      desc: "Utilize planning techniques and tools including Materials Requirements Planning, Host reports and MS Excel (planning spreadsheets) to prepare supply plansbased on production plan.",
      descBullets: [
        "Considering material master system planning parameters (MOQ, lead time, safety stocks).",
        "Monitor status of open purchase orders to ensure on-time delivery of washing machine materials."
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false// Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "WEB APP Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/Havana.jpeg"),
      projectName: "Havana",
      projectDesc: "Finance Tracker Webapp",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://profitapp-project.web.app/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/JRSouq.png"),
      projectName: "JR Souq",
      projectDesc: "Online shopping clothes store",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://jrsouq.easy-orders.net/"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      imageAlt: "Google Assistant Action Logo",
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: "https://pakistan-olx-1.firebaseapp.com/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Power BI Projects",
  subtitle:
    "Live display of my Power BI projects, including interactive dashboards and advanced analytical reports.",
  displayMediumBlogs: "false", // Set false if you don’t want to fetch external blogs
  blogs: [
    {
      url: "https://app.powerbi.com/view?r=eyJrIjoiMDlhYjg4MTEtNGE5Mi00ZDNjLWJiYzUtZjA2NGZmNmVhNTFmIiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9",
      title: "US Power Outage Analysis (2002-2023)",
      description:
        "An analysis of power outages across the United States from 2002 to 2023, highlighting trends and patterns in power disruptions."
    },
    {
      url: "https://app.powerbi.com/view?r=eyJrIjoiNDgzMThkNDQtNmQxMi00MzRjLTkwMzQtOGQwMThhNjE1OGVmIiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9",
      title: "Comprehensive Sales Performance Analysis",
      description:
        "A comprehensive analysis of sales performance across channels and categories, covering revenue, orders, and ATP."
    },
    {
      url: "https://app.powerbi.com/view?r=eyJrIjoiZWRkMDU2NWUtMzRkNi00N2E4LWJkNDctZjA0ZjkzZjI3ZjQ2IiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9",
      title: "Revenue and Profit Margin Analysis",
      description:
        "An interactive tool to analyze revenue and profit margins, providing insights on supplier, team, and sales performance by category."
    },
    {
      url: "https://app.powerbi.com/view?r=eyJrIjoiZjI3YTEwZTMtZWRlYi00Y2VhLWFmMTQtYmM5NDkyNjgwZDg4IiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9",
      title: "Purchase Operations Analysis",
      description:
        "Detailed purchase operations analysis over the years, tracking orders and purchase requisitions across purchasing groups and currencies."
    },
    {
      url: "https://app.powerbi.com/view?r=eyJrIjoiMzk4NDI5ZWUtZDE4Zi00OTBhLWFjOTAtZTMwNmQ1ZDJmMGQ2IiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9",
      title: "User Fitness Data Analysis",
      description:
        "An interactive dashboard displaying fitness data such as calories, steps, stairs climbed, and various fitness activities using FitBit data."
    },
    {
      url: "https://app.powerbi.com/view?r=eyJrIjoiNmM5YmQ3YjktODkwZS00MDU0LWE3MjctNzYxYTA5MzUxMTc5IiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9",
      title: "Sales Performance by Product and Country",
      description:
        "An interactive dashboard to analyze sales performance by unit, product, category, and country, covering sales, profits, and costs over time."
    },
    {
      url: "https://app.powerbi.com/view?r=eyJrIjoiNjVjMDU2NTEtOTJiNS00MTQxLTg3MGEtZDhlNDU3ZWViZDBhIiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9",
      title: "Titanic Passenger Data Analysis",
      description:
        "An interactive 'Titanic Dashboard' providing an overview of passenger survival and fatalities by age, gender, class, and passenger status."
    },
   
  ],
  display: true // Ensure this property is enabled to display the section
};




// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false// Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "Software Developer or Software Engineer",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://podcasters.spotify.com/pod/show/codevcast/embed/episodes/TechTalk---Software-Developer-or-Software-Engineer-e866ou/a-audahs"
  ],
  display: false // Set false to hide this section, defaults to true
};

// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  // Please Provide with Your Podcast embeded Link
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+201028928902",
  email_address: "inaeem.online@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false// Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  blogSection,
  bigProjects,
  achievementSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable,
  resumeSection
};
