import React, { useContext } from "react";
import "./Contact.scss";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import { illustration, contactInfo } from "../../portfolio";
import { Fade } from "react-reveal";
import email from "../../assets/lottie/email";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import StyleContext from "../../contexts/StyleContext";
import { useTranslation } from "react-i18next";
import i18n from "i18next"; // استيراد i18n للتحكم بالاتجاه

export default function Contact() {
  const { isDark } = useContext(StyleContext);
  const { t } = useTranslation(); // استخدام الترجمة

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main contact-margin-top" id="contact" dir={i18n.dir()}> {/* تحديد اتجاه الصفحة بناءً على اللغة */}
        <div className="contact-div-main">
          <div className="contact-header">
            <h1 className="heading contact-title">{t('contact_title')}</h1> {/* استخدام مفتاح الترجمة للعنوان */}
            <p
              className={
                isDark
                  ? "dark-mode contact-subtitle"
                  : "subTitle contact-subtitle"
              }
            >
              {t('contact_subtitle')} {/* استخدام مفتاح الترجمة للنص الفرعي */}
            </p>
            <div
              className={
                isDark ? "dark-mode contact-text-div" : "contact-text-div"
              }
            >
              {contactInfo.number && (
                <>
                  <a
                    className="contact-detail"
                    href={"tel:" + contactInfo.number}
                  >
                    {contactInfo.number}
                  </a>
                  <br />
                  <br />
                </>
              )}
              <a
                className="contact-detail-email"
                href={"mailto:" + contactInfo.email_address}
              >
                {contactInfo.email_address}
              </a>
              <br />
              <br />
              <SocialMedia />
            </div>
          </div>
          <div className="contact-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={email} />
            ) : (
              <img
                alt="Man working"
                src={require("../../assets/images/contactMailDark.svg")}
              ></img>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
}
