import React, { useContext } from "react";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import landingPerson from "../../assets/lottie/landingPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { illustration, greeting } from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import { useTranslation } from "react-i18next";
import i18n from "i18next"; // استيراد i18n للتحكم بالاتجاه

export default function Greeting() {
  const { isDark } = useContext(StyleContext);
  const { t } = useTranslation(); // استخدام hook الترجمة

  if (!greeting.displayGreeting) {
    return null;
  }

  return (
    <Fade bottom duration={1000} distance="40px">
      <div
        className="greet-main"
        id="greeting"
        dir={i18n.dir()} // تحديد الاتجاه بناءً على اللغة المختارة فقط لهذا القسم
      >
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1 className={isDark ? "dark-mode greeting-text" : "greeting-text"}>
                {" "}
                {t("greeting_title")}{" "}
                <span className="wave-emoji">{emoji("👋")}</span>
              </h1>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                {t("greeting_subTitle")}
              </p>
              <SocialMedia />
              <div className="button-greeting-div">
               <Button text={t("contact_me")} href="#contact" />
                {greeting.resumeLink && (
                <a
                href={require("./resume.pdf")}
                download="Resume.pdf"
                className="download-link-button"
                style={{ textDecoration: "none" }}
               >
                <Button text={t("download_Resume")} />
               </a>
  )}
</div>

            </div>
          </div>
          <div className="greeting-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={landingPerson} />
            ) : (
              <img
                alt="man sitting on table"
                src={require("../../assets/images/manOnTable.svg")}
              ></img>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
}
