import React, { useRef } from "react";
import "./BlogCard.scss";

export default function BlogCard({ blog, isDark }) {
  const iframeRef = useRef(null);

  // Function to open fullscreen within the page
  const openFullscreen = () => {
    if (iframeRef.current) {
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      } else if (iframeRef.current.mozRequestFullScreen) { // Firefox
        iframeRef.current.mozRequestFullScreen();
      } else if (iframeRef.current.webkitRequestFullscreen) { // Chrome, Safari, and Opera
        iframeRef.current.webkitRequestFullscreen();
      } else if (iframeRef.current.msRequestFullscreen) { // IE/Edge
        iframeRef.current.msRequestFullscreen();
      }
    }
  };

  return (
    <div className={isDark ? "project-container dark-mode" : "project-container"}>
      <div className={isDark ? "dark-mode project-card project-card-shadow" : "project-card"}>
        <h3 className={isDark ? "small-dark project-title" : "project-title"}>
          {blog.title}
        </h3>
        <p className={isDark ? "small-dark small" : "small"}>
          {blog.description}
        </p>
        <div className="powerbi-iframe-container">
          <iframe
            ref={iframeRef}
            title={blog.title}
            src={blog.url}
            width="100%"
            height="400px"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        <button
          onClick={openFullscreen}
          className="fullscreen-button"
        >
          Fullscreen
        </button>
      </div>
    </div>
  );
}
