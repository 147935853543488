import React, { useContext } from "react";
import "./Footer.scss";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";
import StyleContext from "../../contexts/StyleContext";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { isDark } = useContext(StyleContext);  // الحصول على النمط المظلم أو الفاتح من السياق
  const { t, i18n } = useTranslation();  // استخدام الترجمة واللغة الحالية

  // تحديد الاتجاه بناءً على اللغة المختارة (rtl للعربية وltr للغات الأخرى)
  const direction = i18n.language === "ar" ? "rtl" : "ltr";

  return (
    <Fade bottom duration={1000} distance="5px">
      <div className="footer-div" dir={direction}> {/* ضبط اتجاه النصوص بناءً على اللغة */}
        <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          {emoji(t("copyright"))} {/* ترجمة النص مع دعم الرموز التعبيرية */}
        </p>
        <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          {t("developed_by")}{" "} {/* ترجمة النص */}
          <a href="https://inaeem.online">
            {t("developer_name")} {/* ترجمة اسم المطور */}
          </a>
        </p>
      </div>
    </Fade>
  );
}
