import React from "react";
import "./Progress.scss";
import { illustration } from "../../portfolio";
import { Fade } from "react-reveal";
import Build from "../../assets/lottie/build";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import { useTranslation } from "react-i18next";
import i18n from "i18next"; // استيراد i18n للتحكم بالاتجاه

// تعريف techStack بشكل ثابت مع النصوص المترجمة
const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "stack_1", // مفتاح الترجمة الأول
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "stack_2", // مفتاح الترجمة الثاني
      progressPercentage: "70%"
    },
    {
      Stack: "stack_3", // مفتاح الترجمة الثالث
      progressPercentage: "80%"
    },
    {
      Stack: "stack_4", // مفتاح الترجمة الرابع
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section
};

export default function StackProgress() {
  const { t } = useTranslation(); // استخدام الترجمة

  if (techStack.viewSkillBars) {
    return (
      <Fade bottom duration={1000} distance="20px">
        <div className="skills-container" dir={i18n.dir()}> {/* تحديد اتجاه القسم بناءً على اللغة */}
          <div className="skills-bar">
            <h1 className="skills-heading">{t('proficiency_title')}</h1> {/* استخدام مفتاح الترجمة للعنوان */}
            {techStack.experience.map((exp, i) => {
              const progressStyle = {
                width: exp.progressPercentage
              };
              return (
                <div key={i} className="skill">
                  <p>{t(exp.Stack)}</p> {/* استخدام الترجمة لعرض اسم التقنية أو المهارة */}
                  <div className="meter">
                    <span style={progressStyle}></span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="skills-image">
            {illustration.animated ? (
              <DisplayLottie animationData={Build} />
            ) : (
              <img
                alt="Skills"
                src={require("../../assets/images/skill.svg")}
              />
            )}
          </div>
        </div>
      </Fade>
    );
  }
  return null;
}
