import React, { useContext } from "react";
import "./WorkExperience.scss";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import { workExperiences } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import { useTranslation } from "react-i18next";
import i18n from "i18next"; // استيراد i18n للتحكم بالاتجاه

export default function WorkExperience() {
  const { isDark } = useContext(StyleContext);
  const { t } = useTranslation(); // استخدام الترجمة

  if (workExperiences.display) {
    return (
      <div id="experience" dir={i18n.dir()}> {/* إدارة اتجاه القسم بناءً على اللغة */}
        <Fade bottom duration={1000} distance="20px">
          <div className="experience-container" id="workExperience">
            <div>
              <h1 className="experience-heading">{t('experiences_title')}</h1> {/* استخدام الترجمة للعنوان */}
              <div className="experience-cards-div">
                {workExperiences.experience.map((card, i) => {
                  return (
                    <ExperienceCard
                      key={i}
                      isDark={isDark}
                      cardInfo={{
                        company: t(`company_${i + 1}`),  // ترجمة اسم الشركة
                        desc: t(`desc_${i + 1}`),       // ترجمة الوصف
                        date: t(`date_${i + 1}`),                // يمكن إبقاء التاريخ كما هو أو ترجمته إذا لزم الأمر
                        companylogo: card.companylogo,
                        role: t(`role_${i + 1}`),       // ترجمة الدور
                        descBullets: card.descBullets.map((bullet, idx) => t(`bullet_${i + 1}_${idx + 1}`))  // ترجمة النقاط التفصيلية
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
  return null;
}
