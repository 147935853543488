import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitch.scss'; // تأكد من أن التنسيقات يتم استيرادها

function LanguageSwitch() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'ar' : 'en';
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={language === 'ar'}
        onChange={toggleLanguage}
      />
      <span className="slider round">
        <span className="emoji">{language === 'en' ? '🇬🇧' : '🇪🇬'}</span>
      </span>
    </label>
  );
}

export default LanguageSwitch;
