import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import './i18n'; // استيراد إعدادات الترجمة
import './index.css';


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// إذا كنت تريد أن يعمل تطبيقك في وضع عدم الاتصال وتحميله بشكل أسرع، 
// يمكنك تغيير unregister() إلى register() أدناه. 
// لاحظ أن هذا يأتي مع بعض التحديات.
// تعرف على المزيد حول service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
